import ApiHandler from '@/utils/handlers/ApiHandler'
import { paramHelper } from '@/utils/helpers/api/paramHelper'
import {
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  SET_LOGOUT,
  SET_SEARCHED_SCHOOLS,
  SET_TEACHER_TYPES,
  SET_TEACHER_PROFILE,
  CLEAR_AUTH_ERROR
} from '@/store/modules/auth/mutations'
import LocalDataHandler from '@/utils/handlers/LocalDataHandler'

export const GET_TEACHER_TYPES = 'getTeacherTypes'
export const SEARCH_SCHOOLS = 'searchSchools'
export const CHECK_BRIN = 'checkBrin'

/** Register **/
export const REGISTER_TEACHER = 'registerTeacher'

/** Set teacher school **/
export const SET_TEACHER_SCHOOL = 'setTeacherSchool'

/** Login **/
export const SEND_TEACHER_LOGIN = 'sendTeacherLogin'

/** Logout **/
export const LOGOUT = 'logout'

/** Verify **/
export const VERIFY_EMAIL = 'verifyEmail'
export const RESEND_VERIFICATION_EMAIL = 'resendVerificationEmail'


export const FETCH_TEACHER = 'fetchTeacher'
export const CLEAR_TEACHER = 'clearTeacher'
export const FORGET_PASSWORD_REQUEST = 'RequestPasswordRequest'
export const FORGET_PASSWORD = 'RequestPassword'
export const VERIFY_PASSWORD_RESET_TOKEN = 'verifyPasswordResetToken'
export const REMOVE_AUTH_ERROR = 'removeAuthError'


export const actions = {
  checkBrin({ commit }, payload) {
    const formData = new FormData()
    formData.append('brin', payload.brin)
    formData.append('schoolId', payload.schoolId)

    return ApiHandler.post('school/validate-brin', formData).then(response => {
      return response.data.brinMatchesSchoolId
    }).catch(error => {
      console.log(error)
    })
  },
  getTeacherTypes({ commit }) {
    return ApiHandler.get('/teacher/get-teacher-types').then(response => {
      if (response) {
        commit(SET_TEACHER_TYPES, response.data)
        return true
      }
      return false
    }).catch(error => {
      console.log(error)
      // TODO: Commit the API error.
    })
  },
  searchSchools({ commit }, payload) {
    const endParams = {
      searchQuery: payload.searchQuery,
      page: payload.page,
      sortString: paramHelper.createSortString(payload.sorting),
      limit: 15
    }
    const endTerms = paramHelper.createUrlParams(endParams)
    return ApiHandler.get(`/school/search-schools?${endTerms}`).then(response => {
      if (response) {
        commit(SET_SEARCHED_SCHOOLS, response.data)
        return true
      }
      return false
    }).catch(error => {
      console.log(error)
      // TODO: Commit the API error.
    })
  },
  fetchTeacher({ commit }) {
    return ApiHandler.get('teacher/get-teacher-profile').then((response) =>
    {
      commit(SET_TEACHER_PROFILE, response.data)
    })
  },
  clearTeacher({ commit }) {
    commit(SET_TEACHER_PROFILE, {})
  },

  /** Register **/
  registerTeacher(context, payload) {
    const formData = new FormData()
    formData.append('firstName', payload.firstName)
    formData.append('lastName', payload.lastName)
    // todo remove from backend and frontend
    formData.append('teacherTypeId', '1')
    formData.append('email', payload.email)
    formData.append('phoneNumber', payload.phoneNumber ?? '')
    formData.append('password', payload.password)
    // todo sync naming front and backend
    formData.append('receiveNewsletterGeneral', payload.receiveEmails)
    formData.append('receiveNewsletterWeekMedia', payload.receiveNewsletter)
    formData.append('recaptchaResponse', payload.recaptchaResponse)

    if (payload.inviteToken) {
      formData.append('inviteToken', payload.inviteToken)
    }

    return ApiHandler.post('auth/teacher/register', formData)
      .then(response => {
        if (response) {
          return response
        }
        return false
      }).catch(error => {
        throw error
      // TODO: Commit the API error.
      })
  },

  /** Set teacher school **/
  setTeacherSchool(context, payload) {
    const formData = new FormData()
    formData.append('email', payload.email)
    formData.append('schoolId', payload.schoolId)
    formData.append('brin', payload.brin)

    return ApiHandler.post('auth/set-teacher-school', formData)
      .then(response => {
        if (response) {
          return response
        }
        return false
      }).catch(error => {
        throw error
      // TODO: Commit the API error.
      })
  },

  /** Login **/
  // TODO: We can use one login and send the grant_type with the payload.
  sendTeacherLogin({ commit }, payload) {
    const formData = new FormData()
    formData.append('email', payload.email)
    formData.append('password', payload.password)
    formData.append('passcode', payload.passcode)
    formData.append('grant_type', payload.userTypeScope)
    formData.append('client_id', process.env.VUE_APP_CLIENT_ID_API)
    formData.append('rememberMe', payload.rememberMe ? payload.rememberMe : false)


    return ApiHandler.post('auth/login', formData).then(response => {
      if (response) {
        if (response.statusText === 'passwordOutdated') {
          return response
        }
        commit(LOGIN_SUCCESS, response.data)
        // ApiHandler.setAuthHandler(true)
        LocalDataHandler.setAuthenticated()
        return response
      }
      return false
    }).catch(error => {
      console.log(error)
      commit(LOGIN_FAILURE, error.response.data)
      return error.response.data
    })
  },

  /** Logout **/
  logout({ commit }, payload) {
    return ApiHandler.post('auth/logout').then(response => {
      if (response) {
        commit(SET_LOGOUT, response.data)
        return true
      }
      return false
    }).catch(error => {
      console.log(error)
      // TODO: Commit the API error.
    })
  },

  /** Verify **/
  verifyEmail({ commit }, token) {
    return ApiHandler.post(`auth/verify-email/${token}`).then(response => {
      if (response) {
        return response
      }
      return false
    }).catch(error => {
      return error.response
      // TODO: Commit the API error.
    })
  },
  resendVerificationEmail({ commit }, email) {
    const formData = new FormData()
    formData.append('email', email)
    return ApiHandler.post('auth/resend-verification-email', formData).then(response => {
      if (response) {
        return response
      }
    }).catch(error => {
      console.log(error.response)
      return error.response
    })
  },


  RequestPasswordRequest({ commit }, payload) {
    const formData = new FormData()
    formData.append('email', payload.email)
    formData.append('recaptchaResponse', payload.recaptchaResponse)
    return ApiHandler.post('auth/forgot-password', formData).then(response => {
      if (response) {
        return response
      }
      return false
    }).catch(error => {
      return error.response
    })
  },

  RequestPassword({ commit }, payload) {
    const formData = new FormData()
    formData.append('newPassword', payload.password)
    return ApiHandler.post(`auth/reset-password/${payload.token}`, formData, null).then(response => {
      console.log(response)
      if (response) {
        return response
      }
      return false
    })
      .catch(error => {
      })
  },
  verifyPasswordResetToken({ commit }, token) {
    const formData = new FormData()
    formData.append('token', token)
    return ApiHandler.post('auth/verify-reset-password-token', formData).then(response => {
      if (response) {
        return response
      }
      return false
    }).catch(error => {
      console.log(error)
      return error.response
    })
  },
  removeAuthError({ commit }) {
    commit(CLEAR_AUTH_ERROR)
  }
}
