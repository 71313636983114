import ApiHandler from '@/utils/handlers/ApiHandler'
import { statsDummy } from '../../../utils/data/statsDummy'
import { createByFormData } from '../../../utils/helpers/api/commonCallsHelper'
import { paramHelper } from '../../../utils/helpers/api/paramHelper'
import { SET_PROVINCES, SET_TEACHER_DETAILS, SET_TEACHERS, SET_CLASSES, SET_CLASS_DETAILS, SET_RANKING_HELPDESK, SET_STATS } from './mutations'

export const GET_TEACHERS = 'getTeachers'
export const GET_PROFESSIONALS = 'getProfessionals'

export const GET_TEACHER_DETAILS = 'getTeacherDetails'
export const LINK_TEACHER_TO_CLASS = 'linkTeacher'
export const UNLINK_TEACHER_TO_CLASS = 'unlinkTeacher'
export const DELETE_TEACHER = 'deleteTeacher'
export const DELETE_SCHOOL = 'deleteSchool'
export const SEARCH_CLASSES = 'searchClasses'

export const CREATE_SCHOOL = 'createSchool'

export const CREATE_TEACHER = 'createTeacher'
export const HELPDESK_EDIT_TEACHER = 'helpdeskEditTeacher'


export const GET_CLASS_DETAILS = 'getClassDetails'
export const GET_MISSIONS_INSIGHT = 'getMissionsInsight'
export const GET_QUESTIONS_INSIGHT = 'getQuestionsInsight'
export const GET_EXTRA_GAMES_INSIGHT = 'getExtraGamesInsight'
export const EDIT_CLASS_DETAILS = 'editClassDetails'
export const HELPDESK_CREATE_CLASS = 'helpdeskCreateClass'

export const GET_SCHOOL_DETAILS = 'getSchoolDetails'
export const RESET_MISSION = 'resetMission'
export const EDIT_BITS_MISSION_SLIDE = 'editBitsMissionSlide'
export const EDIT_BITS_EXTRA_GAME = 'editBitsExtraGame'

export const GET_RANKING_OVERVIEW_HELPDESK = 'getRankingOverviewHelpdesk'
//STATS
export const GET_STATS = 'getStats'

export const GET_PROVINCES = 'getProvinces'


export const actions = {
  getTeachers({ commit }, payload) {
    const params = {
      searchQuery: payload.searchQuery,
      page: payload.page,
      sortString: paramHelper.createSortString(payload.sorting),
      filters: paramHelper.createFilterString(payload.filters),
      limit: 15
    }
    const endTerms = paramHelper.createUrlParams(params)
    return ApiHandler.get(`helpdesk/teacher/get-teachers?${endTerms}`)
      .then(response => {
        if (response) {
          commit(SET_TEACHERS, response.data)
          return true
        }
        return false
      }).catch(error => {
        console.log(error)
      })
  },
  getProfessionals({ commit }, payload) {
    const params = {
      searchQuery: payload.searchQuery,
      page: payload.page,
      sortString: paramHelper.createSortString(payload.sorting),
      filters: paramHelper.createFilterString(payload.filters),
      limit: 15
    }
    const endTerms = paramHelper.createUrlParams(params)
    return ApiHandler.get(`helpdesk/teacher/get-professionals?${endTerms}`)
      .then(response => {
        if (response) {
          commit(SET_TEACHERS, response.data)
          return true
        }
        return false
      }).catch(error => {
        console.log(error)
      })
  },
  getSchoolDetails({ commit }, schoolId) {
    return ApiHandler.get(`helpdesk/school/get-school-details/${schoolId}`).then(response => {
      return response
    }).catch(error => {
      console.log(error)
      return {}
    })
  },
  searchClasses({ commit }, payload) {
    const params = {
      searchQuery: payload.searchQuery,
      page: payload.page,
      sortString: paramHelper.createSortString(payload.sorting),
      filters: paramHelper.createFilterString(payload.filters),
      limit: 15
    }
    const endTerms = paramHelper.createUrlParams(params)
    return ApiHandler.get(`helpdesk/school-classes/get-school-classes?${endTerms}`)
      .then(response => {
        if (response) {
          commit(SET_CLASSES, response.data)
          return true
        }
        return false
      }).catch(error => {
        console.log(error)
      })
  },

  getTeacherDetails({ commit }, teacherId) {
    return ApiHandler.get(`helpdesk/teacher/get-teacher-overview/${teacherId}`)
      .then(response => {
        if (response) {
          commit(SET_TEACHER_DETAILS, response.data)
          return true
        }
        return false
      })
      .catch(error => {
        console.log(error)
        // TODO: Commit the API error.
      })
  },
  getClassDetails({ commit }, classId) {
    return ApiHandler.get(`helpdesk/school-classes/get-school-class/${classId}`)
      .then(response => {
        if (response) {
          commit(SET_CLASS_DETAILS, response.data)
          return true
        }
        return false
      })
      .catch(error => {
        console.log(error)
      // TODO: Commit the API error.
      })
  },
  editClassDetails({ commit }, payload) {
    return createByFormData({ commit }, 'helpdesk/school-classes/edit-school-class', payload)
  },
  linkTeacher({ commit }, ids) {
    const formData = new FormData()
    formData.append('teacherId', ids.teacherId)
    formData.append('schoolClassId', ids.classId)
    return ApiHandler.post('helpdesk/teacher/link-teacher-to-class', formData)
      .then(response => {
        if (response) {
          return true
        }
        return false
      })
      .catch(error => {
        console.log(error)
        // TODO: Commit the API error.
      })
  },
  unlinkTeacher({ commit }, ids) {
    const formData = new FormData()
    formData.append('teacherId', ids.teacherId)
    formData.append('schoolClassId', ids.classId)
    return ApiHandler.post('helpdesk/teacher/unlink-teacher-to-class', formData)
      .then(response => {
        if (response) {
          return true
        }
        return false
      })
      .catch(error => {
        console.log(error)
        // TODO: Commit the API error.
      })
  },
  createTeacher({ commit }, payload) {
    return createByFormData({ commit }, 'helpdesk/teacher/create-teacher', payload)
  },
  helpdeskEditTeacher({ commit }, payload) {
    return createByFormData({ commit }, 'helpdesk/teacher/edit-profile', payload)
  },
  createSchool({ commit }, payload) {
    return createByFormData({ commit }, 'helpdesk/create-school', payload)
  },
  helpdeskCreateClass({ commit }, payload) {
    return createByFormData({ commit }, 'helpdesk/create-school-class', payload)
  },
  deleteTeacher({ commit }, teacherId) {
    return ApiHandler.delete(`helpdesk/teacher/delete-teacher/${teacherId}`)
      .then(response => {
        if (response) {
          return true
        }
        return false
      })
      .catch(error => {
        console.log(error)
        return error.response
        // TODO: Commit the API error.
      })
  },
  deleteSchool({ commit }, schoolId) {
    return ApiHandler.delete(`helpdesk/school/delete-school/${schoolId}`)
      .then(response => {
        if (response) {
          return true
        }
        return false
      })
      .catch(error => {
        console.log(error)
        // TODO: Commit the API error.
      })
  },
  getMissionsInsight({ commit }, classId) {
    return ApiHandler.get(`helpdesk/get-missions-insight/${classId}`).then(response => {
      return response
    }).catch(error => {
      console.log(error)
      return {}
    })
  },
  getQuestionsInsight({ commit }, { classId, payload }) {
    const params = {
      page: payload.page,
      limit: 5
    }
    const endTerms = paramHelper.createUrlParams(params)
    return ApiHandler.get(`helpdesk/get-questions-insight/${classId}?${endTerms}`).then(response => {
      return response
    }).catch(error => {
      console.log(error)
      return {}
    })
  },
  getExtraGamesInsight({ commit }, { classId, payload }) {
    const params = {
      page: payload.page,
      limit: 5
    }
    const endTerms = paramHelper.createUrlParams(params)
    return ApiHandler.get(`helpdesk/get-extra-games-insight/${classId}?${endTerms}`).then(response => {
      return response
    }).catch(error => {
      console.log(error)
      return {}
    })
  },
  resetMission({ commit }, { classId, dayOfMM }) {
    const formData = new FormData()
    formData.append('dayOfMM', dayOfMM)
    formData.append('schoolClassId', classId)
    return ApiHandler.post('helpdesk/reset-mission-progress', formData).then(response => {
      return response
    }).catch(error => {
      console.log(error)
      return {}
    })
  },
  editBitsMissionSlide({ commit }, { id, classId, bitsEarned }) {
    const formData = new FormData()
    formData.append('id', id)
    formData.append('schoolClassId', classId)
    formData.append('bitsEarned', bitsEarned)
    return ApiHandler.post('helpdesk/edit-mission-slide-bits-earned', formData).then(response => {
      return response
    }).catch(error => {
      console.log(error)
      return {}
    })
  },
  editBitsExtraGame({ commit }, { extraGameId, classId, bitsEarned }) {
    const formData = new FormData()
    formData.append('id', extraGameId)
    formData.append('schoolClassId', classId)
    formData.append('bitsEarned', bitsEarned)
    return ApiHandler.post('helpdesk/edit-extra-game-bits-earned', formData).then(response => {
      return response
    }).catch(error => {
      console.log(error)
      return {}
    })
  },
  getRankingOverviewHelpdesk({ commit }, payload) {
    const params = {
      searchQuery: payload.searchQuery,
      page: payload.page,
      sortString: paramHelper.createSortString(payload.sorting),
      filters: paramHelper.createFilterString(payload.filters),
      limit: 15
    }
    const endTerms = paramHelper.createUrlParams(params)
    return ApiHandler.get(`helpdesk/get-class-rankings?${endTerms}`)
      .then(response => {
        if (response) {
          commit(SET_RANKING_HELPDESK, response.data)
          return true
        }
        return false
      })
  },
  getStats({ commit }) {
    return ApiHandler.get('helpdesk/get-week-progress-overview').then(response => {
      if (response) {
        commit(SET_STATS, response.data)
        return response
      }
      return false
    }).catch(error => {
      console.log(error)
      commit(SET_STATS, statsDummy)
    })
  },
  getProvinces({ commit }) {
    return ApiHandler.get('public/get-dutch-provinces').then(response => {
      if (response) {
        commit(SET_PROVINCES, response.data)
        return response.data
      }
      return false
    }).catch(error => {
      console.log(error)
    }) }
}
