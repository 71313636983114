export default class Video {
  constructor(id, fileSize, fileName, createdAt, name) {
    this.name = name
    this.id = id
    this.fileSize = fileSize
    this.fileName = fileName
    this.createdAt = new Date(createdAt)
  }

  static parseDataFromObject(data) {
    return new Video(data.id, data.mediaFileId, data.mediaFileUrl, data.createdAt, data.name)
  }
}