import { SET_API_ERROR } from '@/store/mutations'
import ApiHandler from '@/utils/handlers/ApiHandler'
import { paramHelper } from '@/utils/helpers/api/paramHelper'

import { createByFormData, deleteById, tableSearch } from '../../../utils/helpers/api/commonCallsHelper'

import { SET_MISSION_VIDEOS, SET_ATTACHMENTS, SET_EXTRA_GAMES, SET_FAQ_DETAILS, SET_FAQS, SET_INSTRUCTIONS, SET_MEDIAQUIZZES, SET_MISSION_CATEGORIES, SET_MISSIONS, SET_TAGS, SET_VIDEOS } from './mutations'

export const CREATE_MISSION = 'createMission'
export const EDIT_MISSION = 'editMission'
export const DELETE_MISSION = 'deleteMission'

export const GET_EXTRA_GAMES = 'getExtraGames'
export const CREATE_EXTRA_GAME = 'createExtraGame'
export const DELETE_EXTRA_GAME = 'deleteExtraGame'
export const EDIT_EXTRA_GAME = 'editExtraGame'

export const GET_TAGS = 'getTags'
export const CREATE_TAG = 'createTag'
export const DELETE_TAG = 'deleteTag'

export const GET_INSTRUCTIONS = 'getInstructions'
export const CREATE_INSTRUCTION = 'createInstruction'
export const EDIT_INSTRUCTION = 'editInstruction'
export const DELETE_INSTRUCTION = 'deleteInstruction'
export const REORDER_INSTRUCTION = 'reorderInstruction'

export const GET_ATTACHMENTS = 'getAttachments'
export const CREATE_ATTACHMENT = 'createAttachment'
export const DELETE_ATTACHMENT = 'deleteAttachment'

export const GET_VIDEOS = 'getVideos'
export const CREATE_VIDEOS = 'createVideo'
export const DELETE_VIDEOS = 'deleteVideo'

export const GET_MISSION_VIDEOS = 'getMissionVideos'
export const DELETE_MISSION_VIDEO = 'deleteMissionVideo'
export const CREATE_MISSION_VIDEO = 'createMissionVideo'
export const GET_MISSION_VIDEO = 'getMissionVideo'

export const GET_MEDIAQUIZZES = 'getMediaquizzes'
export const GET_MEDIAQUIZ = 'getMediaQuiz'
export const CREATE_MEDIAQUIZ_SELECT_ANSWER = 'createMediaQuizSelectAnswer'
export const CREATE_MEDIAQUIZ_DRAG_AND_DROP = 'createMediaQuizDragAndDrop'
export const EDIT_MEDIAQUIZ_SELECT_ANSWER = 'editMediaQuizSelectAnswer'
export const EDIT_MEDIAQUIZ_DRAG_AND_DROP = 'editMediaQuizDragAndDrop'
export const DELETE_MEDIAQUIZ = 'deleteMediaQuiz'

export const GET_FAQS = 'getFaqs'
export const CREATE_FAQ = 'createFaq'
export const DELETE_FAQ = 'deleteFaq'
export const EDIT_FAQ = 'editFaq'
export const GET_FAQ_DETAILS = 'getFaqDetails'
export const REORDER_FAQ = 'reorderFaq'


export const actions = {

  createMission({ commit }, payload) {
    return createByFormData({ commit }, 'cms/mission/create-mission', payload)
  },
  editMission({ commit }, payload) {
    return createByFormData({ commit }, 'cms/mission/edit-mission', payload)
  },
  deleteMission({ commit }, missionId) {
    return deleteById('cms/mission/delete-mission/', missionId)
  },
  getMissionVideos({ commit }, payload) {
    return tableSearch('cms/multi-audience-video/get-paginated-videos', payload, commit, SET_MISSION_VIDEOS)
  },
  getMissionVideo({ commit }, missionVideoId) {
    return ApiHandler.get(`cms/multi-audience-video/get-video-details/${missionVideoId}`)
  },
  createMissionVideo({ commit }, payload) {
    return createByFormData({ commit }, 'cms/multi-audience-video/create-video', payload)
  },
  deleteMissionVideo({ commit }, videoId) {
    return deleteById('cms/multi-audience-video/delete-video/', videoId)
  },

  // ExtraGames
  getExtraGames({ commit }, payload) {
    const endParams = {
      searchQuery: payload.searchQuery,
      page: payload.page,
      limit: 15,
    }

    const endTerms = paramHelper.createUrlParams(endParams)
    return ApiHandler.get(`game/extra-games/get-extra-games?${endTerms}`)
      .then(response => {
        if (response) {
          commit(SET_EXTRA_GAMES, response.data)
          return true
        }
        return false
      })
      .catch(error => { console.log(error) })
  },
  createExtraGame({ commit }, payload) {
    return createByFormData({ commit }, 'cms/create-extra-game', payload)
  },
  deleteExtraGame({ commit }, extraGameId) {
    return deleteById('cms/delete-extra-game/', extraGameId)
    // TODO add code to delete extra game
  },

  editExtraGame({ commit }, payload) {
    return createByFormData({ commit }, `cms/edit-extra-game/${payload.id}`, payload)
  },

  createMediaQuizSelectAnswer({ commit }, payload) {
    return createByFormData({ commit }, 'cms/mediaquiz/create/select-answer', payload)
  },
  createMediaQuizDragAndDrop({ commit }, payload) {
    return createByFormData({ commit }, 'cms/mediaquiz/create/drag-and-drop', payload)
  },

  editMediaQuizSelectAnswer({ commit }, payload) {
    return createByFormData({ commit }, 'cms/mediaquiz/edit/select-answer', payload)
  },
  editMediaQuizDragAndDrop({ commit }, payload) {
    return createByFormData({ commit }, 'cms/mediaquiz/edit/drag-and-drop', payload)
  },
  getMediaQuiz({ commit }, payload) {
    return ApiHandler.get(`cms/mediaquiz/get-mediaquiz-details/${payload.id}`)
  },
  getTags({ commit }, payload) {
    let url = '/cms/mission/get-mission-tags'

    if (payload && !(Object.keys(payload).length === 0)) {
      const endParams = {
        searchQuery: payload.searchQuery,
        page: payload.page
      }

      const endTerms = paramHelper.createUrlParams(endParams, false)
      url += '?' + endTerms
    }
    return ApiHandler.get(url)
      .then(response => {
        if (response) {
          commit(SET_TAGS, response.data)
          return true
        }
        return false
      })
      .catch(error => { console.log(error) })
  },
  createTag({ commit }, payload) {
    return createByFormData({ commit }, 'cms/mission/create-mission-tag', payload)
  },
  deleteTag({ commit }, tagId) {
    return deleteById('cms/mission/delete-mission-tag/', tagId)
  },
  getMediaquizzes({ commit }, payload) {
    // Create shallow copy
    const form = JSON.parse(JSON.stringify(payload))
    // Add multiple answer filter as well
    if (form.filters && form.filters.some(filter => filter.id === 7)) {
      form.filters.push({ id: 8, type: 'missionSlideTypeId' })
    }
    const endParams = {
      searchQuery: form.searchQuery,
      page: form.page,
      sortString: paramHelper.createSortString(form.searchQuery),
      filters: paramHelper.createFilterString(form.filters, true),
      limit: 15
    }

    const endTerms = paramHelper.createUrlParams(endParams)
    return ApiHandler.get(`cms/mediaquiz?${endTerms}`)
      .then(response => {
        if (response) {
          commit(SET_MEDIAQUIZZES, response.data)
          return true
        }
        return false
      })
      .catch(error => { console.log(error) })
  },
  getMediaquiz({ commit }, payload) {
    // TODO: Implement the get by ID when it's implemented.
    //return ApiHandler.get()
  },
  deleteMediaQuiz({ commit }, payload) {
    return ApiHandler.delete(`cms/mediaquiz/${payload.id}`)
  },
  getInstructions({ commit }, payload) {
    const endParams = {
      searchQuery: payload.searchQuery,
      sortString: paramHelper.createSortString(payload.sorting),
      page: payload.page,
      limit: 100
    }

    const endTerms = paramHelper.createUrlParams(endParams)
    return ApiHandler.get(`instruction/get-instructions?${endTerms}`).then(response => {
      if (response) {
        commit(SET_INSTRUCTIONS, response.data)
        return true
      }
      return false
    }).catch(error => {
      console.log(error)
      return true
    })
  },

  createInstruction({ commit }, payload) {
    return createByFormData({ commit }, 'instruction/create-instruction', payload)
  },
  editInstruction({ commit }, payload) {
    return createByFormData({ commit }, 'instruction/update-instruction', payload)
  },
  deleteInstruction({ commit }, instructionId) {
    return deleteById('instruction/delete-instruction/', instructionId)
  },
  reorderInstructions({ commit }, payload) {
    const formData = new FormData()
    formData.append('id', payload.id)
    formData.append('instructionIndex', payload.instructionIndex)
    return ApiHandler.post('/cms/mission/reorder-instruction', formData)
  },
  getAttachments({ commit }, payload) {
    const endParams = {
      sortString: paramHelper.createSortString(payload.sorting),
    }

    const endTerms = paramHelper.createUrlParams(endParams)
    return ApiHandler.get(`instruction/get-attachments?${endTerms}`).then(response => {
      if (response) {
        commit(SET_ATTACHMENTS, response.data)
        return true
      }
      return false
    }).catch(error => {
      console.log(error)
      return true
    })
  },
  createAttachment({ commit }, payload) {
    return createByFormData({ commit }, 'instruction/create-attachment', payload)
  },
  deleteAttachment({ commit }, attachmentId) {
    return deleteById('instruction/delete-attachment/', attachmentId)
  },
  getVideos({ commit }, payload) {
    const endParams = {
      searchQuery: payload.searchQuery,
      sortString: paramHelper.createSortString(payload.sorting),
    }

    const endTerms = paramHelper.createUrlParams(endParams)
    return ApiHandler.get(`instruction/get-videos?${endTerms}`).then(response => {
      if (response) {
        commit(SET_VIDEOS, response.data)
        return true
      }
      return false
    }).catch(error => {
      console.log(error)
      return true
    })
  },
  createVideo({ commit }, video) {
    return createByFormData({ commit }, 'instruction/create-video', video)
  },
  deleteVideo({ commit }, videoId) {
    return deleteById('instruction/delete-video/', videoId)
  },
  getFaqs({ commit }, payload) {
    console.log(payload)
    const endParams = {
      searchQuery: payload.searchQuery,
      page: payload.page,
      filters: paramHelper.createFilterString(payload.filters),
      sortString: paramHelper.createSortString(payload.sorting),
      limit: 15
    }

    const endTerms = paramHelper.createUrlParams(endParams)
    return ApiHandler.get(`faq/get-questions?${endTerms}`)
      .then(response => {
        if (response) {
          commit(SET_FAQS, response.data)
          return true
        }
        return false
      })
      .catch(error => { console.log(error) })
  },
  getFaqDetails({ commit }, faqId) {
    return ApiHandler.get(`faq/get-question/${faqId}`).then(response => {
      if (response) {
        commit(SET_FAQ_DETAILS, response.data)
        return true
      }
      return false
    }).catch(error => {
      console.log(error)
      return false
    })
  },
  createFaq({ commit }, payload) {
    return createByFormData({ commit }, 'faq/create-question', payload)
  },
  deleteFaq({ commit }, faqId) {
    return deleteById('faq/delete-question/', faqId)
  },
  editFaq({ commit }, payload) {
    return createByFormData({ commit }, 'faq/update-question', payload)
  },
  reorderFaq({ commit }, payload) {
    const formData = new FormData()
    formData.append('id', payload.id)
    formData.append('questionIndex', payload.questionIndex)
    return ApiHandler.post('/cms/mission/reorder-question', formData)
  },
}
