import TeacherType from '@/models/TeacherType'
import School from '@/models/schoolSearch/School'
import LocalDataHandler from '@/utils/handlers/LocalDataHandler'
import ApiHandler from '@/utils/handlers/ApiHandler'
import apiErrorMsgHandler from '@/utils/handlers/ApiErrorMsgHandler'
import { USER_TYPES } from '@/models/User'
import { scopeToId } from '../../../models/UserTypeIds'
import router from '../../../router/index'
import { ROUTE_NAMES_AUTH } from '@/router/modules/auth'



export const SET_TEACHER_TYPES = 'setTeacherTypes'
export const SET_SEARCHED_SCHOOLS = 'setSearchedSchools'
export const RESET_SEARCHED_SCHOOLS = 'resetSearchedSchools'
export const SET_TEACHER_PROFILE = 'setTeacherProfile'

/** Login **/
export const LOGIN_SUCCESS = 'loginSuccess'
export const LOGIN_FAILURE = 'loginFailure'
export const SET_LOGOUT = 'setLogout'
export const CLEAR_AUTH_ERROR = 'clearAuthError'

export const mutations = {
  setTeacherTypes(state, data) {
    state.teacherTypes = data.map(item => TeacherType.parseDataFromObject(item))
  },
  setSearchedSchools(state, data) {
    data.schools = data.items.map(item => School.parseDataFromObject(item))
    state.searchedSchools = data
  },
  resetSearchedSchools(state) {
    state.searchedSchools = []
  },

  /** Login **/
  loginSuccess(state, payload) {
    // LocalDataHandler.setAccessToken(payload.access_token, payload.expires_in)
    LocalDataHandler.setKeepMeLoggedIn(payload)
    // LocalDataHandler.setRefreshToken(payload.refresh_token, LocalDataHandler.VALUES.REFRESH_TOKEN_EXPIRE_TIME);
    LocalDataHandler.setUser({ type: payload.scope, id: scopeToId(payload.scope), isApproved: payload.isApproved })
    state.isAuthenticated = true
    state.authError = ''
  },
  setTeacherProfile(state, teacherProfile) {
    const user = LocalDataHandler.getUser()
    if (user && (user.type === USER_TYPES.TEACHER || user.type === USER_TYPES.PROFESSIONAL)) {
      state.teacherProfile = teacherProfile
    } else {
      state.teacherProfile = {}
    }
  },
  loginFailure(state, error) {
    state.isAuthenticated = false
    state.authError = apiErrorMsgHandler.convert(error)
  },
  setLogout(state) {
    LocalDataHandler.destroy()
    LocalDataHandler.clearLocalData()
    localStorage.removeItem('access_token')
    // ApiHandler.setAuthHandler(false)
    state.isAuthenticated = false
    router.push({ name: ROUTE_NAMES_AUTH.LOGIN })
  },
  clearAuthError(state) {
    state.authError = ''
  }
}
